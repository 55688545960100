@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght,GRAD@8..144,100..1000,5&display=swap');

@import "libs/vendor/BS4/functions";
@import "libs/ascend/BS4/variables_extended";
// Modify this for custom colors, font-sizes, etc
@import "libs/ascend/bootstrap/mixins";
@import "libs/ascend/bootstrap/mixins_extended";

/* UI-login.css */

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 300;
  src: url("https://d3dn5e5ss95y9g.cloudfront.net/fonts/metropolis-light-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 700;
  src: url("https://d3dn5e5ss95y9g.cloudfront.net/fonts/Metropolis-Bold.woff")
    format("woff");
}

html {
  background: url(backgrounds/detectAI.png) no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;

  &.seasoned-background {
    #loginPanel_outer {
      display: flex;
      justify-content: center;
      align-items: center;

      #detectAI-logo,
      #detectAI-promo {
        display: none;
      }
    }

    &.default {
      background-image: url(backgrounds/background-default.jpg);
    }

    &.winter {
      background-image: url(backgrounds/background-winter.jpg);
    }

    &.spring {
      background-image: url(backgrounds/background-spring.jpg);
    }

    &.summer {
      background-image: url(backgrounds/background-summer.jpg);
    }

    &.fall {
      background-image: url(backgrounds/background-fall.jpg);
    }
  }
}

body.login {
  background-color: transparent;
  height: 100%;
  color: $newBlueDark;
  font-size: 13px;
  margin: 0;
  font-family: 'Roboto Flex', serif;
}

#loginPanel_outer {
  justify-content: center;
  overflow: auto;
  padding: 20px 0;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "login-form"
    "detectai-logo"
    "detectai-promo";
  row-gap: 40px;

  width: 100%;
  height: 100%;

  #detectAI-logo {
    width: 341px;
    height: 132px;
    background: url(login/videa-health-mobile.png) no-repeat;
    grid-area: detectai-logo;
    justify-self: center;
  }

  #detectAI-promo {
    width: 355px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Metropolis", sans-serif;
    font-weight: 300;
    color: #fff;
    grid-area: detectai-promo;
    justify-self: center;

    strong,
    h1,
    h3 {
      font-weight: 700;
    }

    h1 {
      font-size: 37px;
      margin: 0;
    }

    .promo-body {
      text-align: justify;
      font-size: 18px;
      margin: 25px 0;
    }

    .promo-footer h3 {
      margin-bottom: 3px;
      font-size: 24px;
    }

    .promo-footer a {
      font-size: 21px;
      color: #fff;
    }
  }

  #loginPanel {
    width: 340px;
    height: 550px;
    padding: 45px 30px;
    background-color: #fff;
    position: relative;
    @include borderRadius(3px);
    grid-area: login-form;
    justify-self: center;

    .loginField {
      width: 180px;
      @include boxShadowInsetImportant(0, 0, 0, 50px, #fff);
    }

    a {
      color: $newBlueLight;
    }

    #list-organization a {
      color: #555 !important;
    }

    h1 {
      font-size: 22px;
      margin: 30px 0 10px;
    }

    label {
      &.control-label {
        color: $newBlueDark !important;
      }

      img.errorIcon {
        display: none !important;
      }

      &.labelError {
        img.errorIcon {
          display: inline-block !important;
        }
      }
    }

    input.error {
      border-color: $red !important;
      &:focus {
        border-color: $red;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.025), 0 0 8px $white;
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.025), 0 0 8px $white;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.025), 0 0 8px $white;
      }
    }

    .icon-white {
      background-image: url(glyphicons-halflings-white.png);
    }

    legend {
      color: $newBlueDark;
      border-bottom: 1px solid $newBlueLight;
      margin-top: 45px;
    }
  }

  .content {
    border-radius: 1px;
  }
}

#loginLogo {
  background: url(newLogo.svg) no-repeat;
  width: 280px;
  margin-top: 7px;
  height: 43px;
}

#betaLoginLogo {
  background: url(newBetaLogo.svg) no-repeat;
  width: 300px;
  height: 50px;
}

#HSOne_logo {
  position: absolute;
  bottom: 30px;
  background: url(logo_HSOne.png) no-repeat;
  width: 148px;
  height: 23px;
  background-size: 148px 23px;
}

.paddedTop {
  margin-top: 35px;
}

.formElement {
  margin-bottom: 20px;
}

a {
  color: $newBlueLight;
  text-decoration: none;
  font-size: 12px;
}

a:hover {
  text-decoration: underline;
}

#send {
  border: 1px solid $newBlueDark;
  cursor: pointer;
  background: $newBlueDark;
  box-shadow: none;

  &:active,
  &:hover {
    background: lighten($newBlueDark, 10%);
    box-shadow: none;
  }
}

#session {
  float: right;
  margin-top: -25px;
  margin-right: 0;
}

#description {
  margin-bottom: 15px;
  line-height: 16px;
}

#upgradeBrowser .panel {
  width: auto;
  padding-right: 0;

  legend {
    color: #1b3667;
    border-bottom: 1px solid #5988ab;
    margin-top: 45px;
  }

  p {
    color: #1b3667;
    padding-left: 0;
    font-size: 13px;
    line-height: 17px;
  }
}

.browserContainer {
  @include box-sizing(border-box);
  width: 50%;
  height: auto;
  text-align: center;

  &.chromeBrowser {
    float: left;
    padding-left: 13px;

    a {
      display: block;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.safariBrowser {
    float: right;
    padding-right: 13px;
  }

  .browserIcon {
    display: inline-block;
    border: 1px solid #6ec4e9;
    height: 71px;
    width: 71px;
    @include borderRadius(10px);
    background: transparent no-repeat center center;
    background-size: 47px 47px;

    &.chrome {
      background-image: url(chrome.png);
      &:hover {
        background-color: rgba(110, 196, 233, 0.2);
      }
      &:active {
        background-color: rgba(110, 196, 233, 1);
      }
    }

    &.safari {
      background-image: url(safari.png);
    }
  }

  .browserType {
    color: #1b3667;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 21px;
  }

  .systemType {
    color: #1b3667;
    text-align: center;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.3;
    padding-top: 5px;
  }
}

.loginLink {
  display: block;
  float: left;
  margin: 30px 0 0 0;
}

label {
  line-height: 20px;
  margin-bottom: 5px;
}

.warning-sign {
  background: url(alert_warning.svg) no-repeat;
  width: 18px;
  height: 17px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: -2px;
}

#cogButton {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

#settingsPanel {
  position: relative;
}

#settingsPanel h1 {
  font-size: 15px;
  margin-top: 5px;
  text-align: center;
}

.colorIcon {
  width: 18px;
  height: 18px;
  background-color: #cc0000;
  border: solid 1px #fff;
  margin: 4px;
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
}

#displaySettings {
  position: absolute;
  left: 0;
  top: 0;
}

#message {
  @include borderRadius(2px);
  display: none;
  position: fixed;
  z-index: 9000;
  padding: 8px 18px;
  top: 50px;
  left: 50%;
  width: 450px;
  margin-left: -243px;
  font-size: 13px;
  text-align: center;
  font-style: normal !important;

  .close {
    display: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    float: right;
    margin: 0 8px 0 4px;
    background: url(close_icon.png) no-repeat;
  }

  &.notification {
    @include boxShadow(0, 0, 2px, 0, #258815);
    border: solid 1px #258815;
    background-color: #deffd9;
    color: darken(#258815, 5%);
  }

  &.error {
    @include boxShadow(0, 0, 2px, 0, #cc0000);
    border: solid 1px #cc0000;
    background-color: #ffe1e2;
    color: darken(#cc0000, 5%);
  }

  &.warning {
    @include boxShadow(0, 0, 2px, 0, #a46a41);
    border: solid 1px #a46a41;
    background-color: #fefccb;
    color: darken(#a46a41, 5%);

    .close {
      display: block;
    }
  }
}

/* form messages */

.formMessage {
  @include borderRadius(2px);

  margin: 10px 0;

  padding: 8px 18px;

  font-size: 13px;
  text-align: center;
  font-style: normal !important;

  &.notification {
    border: solid 1px #258815;
    background-color: #deffd9;
    color: darken(#258815, 5%);
  }

  &.error {
    border: solid 1px #cc0000;
    background-color: #ffe1e2;
    color: darken(#cc0000, 5%);
  }

  &.warning {
    border: solid 1px #a46a41;
    background-color: #fefccb;
    color: darken(#a46a41, 5%);
  }
}

input.error {
  border: 1px solid $red;
}

#show-organization {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 10px;

    h1 {
      margin-top: 10px !important;
      margin-bottom: 20px !important;
    }
  }

  .buttons {
    padding: 0 10px 10px 10px;

    a {
      font-size: 13px;
      color: $newBlueLight !important;
    }
  }

  form {
    margin-bottom: 0;
  }
}

.property-label {
  color: #999 !important;
}

.fieldcontain {
  margin-bottom: 16px;
}

.systemLogout {
  width: 50px;
  height: 30px;
  display: block;
  color: #fff !important;
  position: relative;
  float: right;
  margin-top: -75px;
  background-color: #37637d;
  text-align: right;
}

#offlineMessage {
  legend {
    color: $newBlueDark;
    border-bottom: 1px solid $newBlueLight;
  }

  p {
    color: $newBlueDark;
    padding-left: 0;
    font-size: 14px;
  }
}

.verifyEmail {
  #login-form {
    .form-actions {
      padding-left: 70px;
    }
  }

  .notification {
    color: #258815;
    font-weight: bolder;
  }

  .error {
    color: #cc0000;
    font-weight: bolder;
  }
}

#login-form {
  .control-group:first-of-type {
    margin-top: 10px;
  }

  .form-actions {
    padding-left: 80px;
    background-color: transparent;
    border-top: 1px solid $newBlueLight;

    #send {
      width: 120px;
    }
  }

  .controls {
    margin-left: 100px;
  }

  .control-label {
    width: 90px;
  }

  #password {
    width: 180px;
  }

  .forgotPassword {
    float: right;
    margin-top: 8px;
    color: $newBlueLight;
  }

  .sso-warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    padding-bottom: 10px;
    margin-top: 15px;
    border-radius: 4px;
    background: #FCF8E4;
    text-align: center;

    span {
      padding: 22px 26px 13px;
    }

    .aboutSSO {
      line-height: 21px;
    }

    .button-space {
      margin-bottom: 20px;
    }
  }
}

.oauthEnabled {
  &#loginPanel_outer {
    #loginPanel {
      height: 706px;
    }
  }
}

#capsLockAlert {
  border: none;
  display: none;
  background-color: transparent;
  text-shadow: none;
  box-shadow: none;
  padding-left: 80px;
}

#forgot-password-form {
  .form-actions {
    padding-left: 80px;
    background-color: transparent;
    border-top: 1px solid $blueLight;

    #send {
      width: 120px;
    }
  }

  .controls {
    margin-left: 100px;
  }

  #organization,
  #username {
    width: 180px;
  }

  .control-label {
    width: 90px;
    color: $blueDark;
  }
}

#reset-password-form {
  .control-label {
    width: 130px;
  }

  .controls {
    margin-left: 140px;

    input {
      width: 140px;
    }
  }

  .warningIcon {
    background: url(warning.svg) no-repeat;
    vertical-align: middle;
    transform: scale(0.69);
    display: none;
    height: 16px;
    width: 18px;
  }

  .control-label {
    &.securityWarning:not(.labelError) {
      .warningIcon {
        display: inline-block;
      }
    }
  }

  #capsLockAlert {
    border: none;
    display: none;
    background-color: transparent;
    text-shadow: none;
    box-shadow: none;
    padding-left: 80px;
  }

  .form-actions {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    background-color: transparent;
    border-top: 1px solid $blueLight;

    input {
      text-transform: capitalize;
      width: 124px;
    }
  }
}

.organization-selector {
  text-align: center;
  font-size: small;

  .HSOne_logo {
    display: inline-block;
    background: url(logo_HSOne.png) no-repeat;
    width: 148px;
    height: 23px;
    background-size: 148px 23px;
  }

  > * {
    margin-top: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }
  p {
  }
  input {
    border: solid 1px #c9cace;
    border-radius: 8px;
    font-size: inherit;
    padding: 8px 16px;
    width: 100%;
  }
  input[type="submit"] {
    color: #fff;
    background-color: #084b8b;
    padding: 10px;
    border: 0;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .login-changing-msg {
    background-color: #fdf1dd;
    padding: 20px;
  }
}

.openIdConnect {
  #loginPanel_outer #loginPanel {
    height: 630px;

    .extended-label {
      text-align: center;
      padding-bottom: 20px;
      width: 280px;
      white-space: pre-line;

      &.prescription {
        display: block;
      }

      &.failed-attempt-message {
        display: none;
        font-size: 18px;
        color: #B22624;

        p {
          font-size: 14px;
        }
      }
    }

    h1 {
      color: $newBlueDark;
      border-bottom: 1px solid $newBlueLight;
      margin: 45px 0 0;
      font-size: 19.5px;
      font-weight: 400;
      line-height: 30px;
    }

    .succeeded-email {
      font-weight: bold;
    }

    .self-registration-form-container {
      border-bottom: 1px solid $blueLight;
      padding: 20px;

      #self-registration-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .control-label {
          width: 90px;
        }

        .controls {
          margin-left: 100px;
        }
      }
    }

    .alternative-way-info {
      white-space: pre-line;
      text-align: center;
      padding-top: 20px;
    }

    .successful-registration-message-container {
      display: none;
      text-align: center;
      white-space: pre-line;
    }

    &.failedRegistration {
      height: 660px;

      .extended-label {
        &.prescription {
          display: none;
        }

        &.failed-attempt-message {
          display: block;
        }
      }
    }
    &.succeededRegistration {
      height: 475px;

      .successful-registration-message-container {
        display: block;
      }

      .self-registration-form-container #self-registration-form {
        display: none;
      }

      .alternative-way-info {
        display: none;
      }
    }
  }
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media screen and (min-width: 860px) and (min-height: 1100px) {
  #loginPanel_outer {
    grid-template-areas:
      "detectai-logo detectai-promo"
      "login-form login-form";
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-items: center;
    align-content: center;
    overflow: hidden;
    gap: 70px 55px;

    #detectAI-promo {
      width: 365px;

      h1 {
        font-size: 40px;
      }

      .promo-body {
        font-size: 21px;
      }

      .promo-footer h3 {
        font-size: 25px;
      }

      .promo-footer a {
        font-size: 22px;
      }
    }

    #detectAI-logo {
      width: 262px;
      height: 394px;
      background-image: url(login/videa-health.png);
      background-size: contain;
      margin-top: 8px;
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 1280px) {
  #loginPanel_outer {
    grid-template-areas: "detectai-logo login-form detectai-promo";
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    justify-items: center;
    align-content: center;
    gap: 0 65px;

    #detectAI-logo {
      background-image: url(login/videa-health.png);
      background-size: contain;
      width: 371px;
      height: 558px;
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 1520px) {
  html {
    background-image: url(backgrounds/detectAI-fullhd.png);
  }

  #loginPanel_outer {
    gap: 0 185px;
  }
}
